

export const  theme={
    colorBgContainer:"#fff",
    components: {
      Button: {
        colorPrimary: '#F4B301',
        algorithm: true, // Enable algorithm
      },
      Input: {
        colorPrimary: '#eb2f96',
        algorithm: true, // Enable algorithm
      },
      Layout:{
        triggerBg:'#667A8A',
        bodyBg	:'#fff'
      },
      
      Menu:{
        itemActiveBg:"#F3FAFF",
        itemBorderRadius:4,
        itemSelectedColor:'#F4B301',
        itemColor:'#5D7285',
        itemHeight	:"64px",
        iconSize	:'32px',
        fontSizeLG:"16px",
        siderBg:"#f00"
      },
      Modal:{
        titleFontSize	:"32px",
      },
      Input:{
        inputFontSize:"13px",
        paddingBlock:"20px",
        paddingInline:"20px",
        activeBorderColor:"#C2C2C2",
        borderRadius:"12px",
      },
      InputNumber:{
        inputFontSize:"13px",
        paddingBlock:"20px",
        paddingInline:"20px",
        activeBorderColor:"#C2C2C2",
        borderRadius:"12px",
      },
      Form:{
        labelColor:"#000000",
        labelFontSize:"16px",
        labelHeight	:"20px",
        labelRequiredMarkColor	:"#F42323",
      },
      Table:{
        headerBg:"#F4F4F5",
        headerColor:"#71717A",
        cellFontSize	:"16px",
        cellPaddingBlock	:"10px"
      }

    },
  }