import React, { useState, useEffect, memo } from "react";
import { Table, Typography } from "antd";
// import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { conversationList } from "../../services/conversationService";
import '../../reducers/dashboardReducer';
import { useSelector } from "react-redux";
// import Highlighter from 'react-highlight-words';
import useTableColumnSearch from "../../utils/useTableColumnSearch";
import ListView from "../../components/common/ListView";

const { Title } = Typography;

const ConversationTable = memo(() => {
  const [data, setData] = useState([]);
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [modalContent, setModalContent] = useState([]);
  // const [instaName, setInstaName] = useState('');
  const { screenSize } = useSelector(({ dashboardReducer }) => dashboardReducer);
  const { getColumnSearchProps } = useTableColumnSearch();

  const fetchData = async () => {
    try {
      const conversationData = await conversationList();
      const flattenedData = conversationData.flatMap(conversation =>
        conversation.messages.map(message => ({
          ...message,
          user: conversation.userId,
          date: message.repliedTimestamp
        }))
      );
      // Explicitly sort the data here, with the latest messages first
      const sortedData = flattenedData.sort((a, b) => {
        const dateA = new Date(a.createdTimestamp);
        const dateB = new Date(b.createdTimestamp);
        return dateB - dateA; // This ensures newest first
      });
      setData(sortedData);
    } catch (error) {
      console.error("Error fetching conversation data:", error);
    }
  };

  // const showModal = (messages, userName) => {
  //   setModalContent(messages);
  //   setIsModalVisible(true);
  //   setInstaName(userName);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  const columns = [
    {
      title: "User Name",
      dataIndex: "user",
      key: "user",
      ...getColumnSearchProps('user'),
    },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   render: (type) => (
    //     <Tag key={type} style={{ marginRight: '8px', padding: '2px 8px' }}>
    //       {type}
    //     </Tag>
    //   ),
    // },
    {
      title: "Feeling",
      dataIndex: "feeling",
      key: "feeling",
      ...getColumnSearchProps('feeling'),
    },
    {
      title: "Query",
      dataIndex: "originalQuery",
      key: "originalQuery",
      width: '30%',
      ...getColumnSearchProps('originalQuery'),
    },
    {
      title: "Response",
      dataIndex: "ai",
      key: "ai",
      width: '30%',
      ...getColumnSearchProps('ai'),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: '20%',
      render: (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit'
        });
      }
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (record) => (
    //     <Button onClick={() => showModal([record.message], record.userName)}>
    //       View Message
    //     </Button>
    //   ),
    // },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>

      {screenSize < 576 ? <ListView
        dataSource={data}
        title={"User Id"}
        description={"Feeling"}
        extraButtonDesc={"Know More"}
        fromPage={"conversation"}
        searchArea={true}
        mainTitle={"Conversations"}
      /> : <>
        <Title level={2} style={{ textAlign: "center", marginBottom: "20px" }}>
          Conversation
        </Title>
        <Table columns={columns} dataSource={data} rowKey="_id" />
      </>}


      {/* <Modal
        title={<span style={{ fontSize: '24px' }}>{`From ${instaName}`}</span>}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {modalContent.map((message) => (
          <p key={message._id}>
            <b>{message.type || "Unknown"}:</b> {message.originalQuery}<br />
            <b>AI:</b> {message.ai}
          </p>
        ))}
      </Modal> */}
    </div>
  );
});

export default ConversationTable;
